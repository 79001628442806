<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard heading="REPORTE">
          <ReporteFormulario></ReporteFormulario>
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ReporteFormulario from '@/gmo_votacion/votar/reporte/components/ReporteFormulario';
export default {
  name: 'Reporte',
  components: { ReporteFormulario },
  data: () => ({
    page: {
      title: 'Reporte'
    },
    breadcrumbs: [
      {
        text: 'Votación',
        disabled: true
      },
      {
        text: 'Reporte',
        disabled: true
      }
    ]
  })
};
</script>

<style scoped></style>
