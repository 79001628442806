<template>
  <div>
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <v-btn color="primary" @click="downloadReport">REPORTE N° 1 (Excel)</v-btn>
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <v-btn color="primary" @click="downloadReportInicio">REPORTE N° 2 (Formato Inicio)</v-btn>
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <v-btn color="primary" @click="downloadReportFin">REPORTE N° 3 (Formato Fin)</v-btn>
        </v-col>
      </v-row>
    </v-card-text>

    <div v-if="showLoading" class="progress-background">
      <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
    </div>
  </div>
</template>

<script>
import ReporteService from '@/gmo_votacion/votar/reporte/services/ReporteService';
import moment from 'moment';
import Swal from 'sweetalert2';

export default {
  name: 'ReporteFormulario',
  data: () => ({
    showLoading: false,
    reporteService: null
  }),
  methods: {
    async downloadReport() {
      this.showLoading = true;
      const ideleccion = localStorage.getItem('oko3ldkd543');
      const token = localStorage.getItem('led17h9p2x');
      const name = `reporte_votaciones_${moment().format('YYYYMMDDHHMMSS')}.xlsx`;
      if (ideleccion !== null) {
        await this.reporteService.downloadResource(
          'reporte',
          {
            ideleccion: ideleccion,
            token: token
          },
          `${name}`
        );
        this.showLoading = false;
      } else {
        this.showLoading = false;
        await this.alertDialog('error', 'SIN ARCHIVO');
      }
    },
    async downloadReportInicio() {
      this.showLoading = true;
      const ideleccion = localStorage.getItem('oko3ldkd543');
      const token = localStorage.getItem('led17h9p2x');
      const name = `reporte_inicio_eleccion_${moment().format('YYYYMMDDHHMMSS')}.pdf`;
      if (ideleccion !== null) {
        await this.reporteService.downloadResource(
          'reporteinicio',
          {
            ideleccion: ideleccion,
            token: token
          },
          `${name}`
        );
        this.showLoading = false;
      } else {
        this.showLoading = false;
        await this.alertDialog('error', 'SIN ARCHIVO');
      }
    },
    async downloadReportFin() {
      this.showLoading = true;
      const ideleccion = localStorage.getItem('oko3ldkd543');
      const token = localStorage.getItem('led17h9p2x');
      const name = `reporte_fin_eleccion_${moment().format('YYYYMMDDHHMMSS')}.pdf`;
      if (ideleccion !== null) {
        await this.reporteService.downloadResource(
          'reportefin',
          {
            ideleccion: ideleccion,
            token: token
          },
          `${name}`
        );
        this.showLoading = false;
      } else {
        this.showLoading = false;
        await this.alertDialog('error', 'SIN ARCHIVO');
      }
    },
    async alertDialog(type, title) {
      return await Swal.fire({
        icon: type,
        title: title,
        confirmButtonText: `OK`
      });
    }
  },

  created() {
    this.reporteService = this.$httpService(new ReporteService(), this);
  }
};
</script>

<style scoped></style>
