class ReporteService {
  constructor() {}
  resources = () => ({
    reporte: {
      uri: `/v1/votaciones/reporte`,
      method: ['get']
    },
    reporteinicio: {
      uri: `/v1/votaciones/reporteinicio`,
      method: ['get']
    },
    reportefin: {
      uri: `/v1/votaciones/reportefin`,
      method: ['get']
    }
  });
}

export default ReporteService;
